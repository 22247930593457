<template>
  <img class="mt30 margin-auto logo" src="https://www.lmlg.top/static/img/public/logo.png" />
  <div class="mt30 wa90">
    <el-form label-position="left" label-width="70px">
      <el-form-item label="邮箱地址">
        <el-input v-model="email_addr" placeholder="请输入您的邮箱地址"></el-input>
      </el-form-item>
      <el-form-item class="relative" label="验证码">
        <el-input v-model="code" placeholder="请输入验证码"></el-input>
        <div class="absolute font12 code" style="color: #ea5503;" v-if="state==0" @click="get_code">发送验证码</div>
        <div class="absolute font12 code color-red" v-if="state==1">{{time}}秒后重新获取</div>
        <div class="absolute font12 code" style="color: #ea5503;" v-if="state==2" @click="get_code">重新获取</div>
      </el-form-item>
      <el-form-item label="账号">
        <el-input v-model="account" placeholder="请自定义账号,建议使用手机号"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="password" :minlength="6" show-password placeholder="请设置密码"></el-input>
      </el-form-item>
    </el-form>
    <div class="login-btn font14 mt20 text-center" @click="register">注册</div>
    <div class="font14 mt10">
      已有账号,&nbsp;去<router-link class="jump-style font14" to="/login" replace>登录</router-link>
    </div>
  </div>

</template>

<script>
  import {email_register,send_email_code} from "@net/login/login";
  import {postFormAPI} from "../../network/home";
  import LocalStorage from '@/localStorage/localstorage'
  let timer=null
  export default {
    name: "registerByEmail",
    data(){
      return{
        account:'',
        email_addr:'',
        code:'',
        password:'',
        time:120,
        state:0
      }
    },
    created() {
      if(this.Utils.has_login()){
        this.Utils.login_back()
      }
    },
    methods:{
      get_timer(){//计时器
        let times=setInterval(()=>{
          if(this.time>0){
            this.time--
          }else {
            this.state=2
            clearInterval(times)
          }
        },1000)
      },
      get_code(){//获取验证码
        if(this.email_addr==''){
          this.Utils.Toast('error','请输入邮箱地址')
          return
        }
        let obj={}
        obj.email_addr=this.email_addr
        if(timer){
          clearTimeout(timer)
        }
        timer=setTimeout(()=>{
          send_email_code(obj).then(res=>{
            if(res.business_code==200){
              this.Utils.Toast('success','发送成功')
              this.state=1
              this.get_timer()
            }else {
              this.Utils.Toast('error',res.msg)
            }
            clearTimeout(timer)
          })
        },800)
      },
      register(){
        if(this.email_addr==''){
          this.Utils.Toast('error','请输入邮箱地址')
          return
        }
        if(this.code==''){
          this.Utils.Toast('error','请输入验证码')
          return
        }
        if(this.account==''){
          this.Utils.Toast('error','请输入账号')
          return
        }
        if(this.password==''){
          this.Utils.Toast('error','请输入密码')
          return
        }
        if(this.password.length<6){
          this.Utils.Toast('error','密码至少六个字符')
          return
        }
        let obj={}
        let arr=['email_addr','code','account','password']
        arr.forEach((item,index)=>{
          obj[item]=this[item]
        })
        if(timer){
          clearTimeout(timer)
        }
        timer=setTimeout(()=>{
          email_register(obj).then(res=>{
            if(res.business_code==200){
              this.Utils.Toast('success','注册成功,正在跳转')
              LocalStorage.save('openid',res.data.openid)
              let times=setTimeout(()=>{
                this.Utils.login_back();
                clearTimeout(times)
                clearTimeout(timer)
              },1000)
            }else {
              this.Utils.Toast('error',res.msg)
              clearTimeout(timer)
            }

          })
        },800)

      }
    }
  }
</script>

<style scoped>
  .logo{
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  .jump-style{
    border: none;
    color: #ea5503;
    text-decoration:none
  }
  .code{
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
  }
  .login-btn{
    color: white;
    background-color: #ea5503;
    line-height: 40px;
    height: 40px;
    border-radius: 5px;
  }
</style>